@import './color-gray';
@import './color-slate';
@import './color-sky';
@import './color-cyan';
@import './color-teal';
@import './color-green';
@import './color-lime';
@import './color-yellow';
@import './color-orange';
@import './color-rust';
@import './color-red';
@import './color-magenta';
@import './color-violet';
@import './color-purple';
@import './color-indigo';
@import './color-blue';

:root {
  // Prism System Colors
  --sbt-black: #000;
  --sbt-black-alpha-10: rgba(0, 0, 0, 0.1);
  --sbt-black-alpha-15: rgba(0, 0, 0, 0.15);
  --sbt-black-alpha-20: rgba(0, 0, 0, 0.2);
  --sbt-black-alpha-80: rgba(0, 0, 0, 0.8);
  --sbt-white: #fff;
  --sbt-white-alpha-10: rgba(255, 255, 255, 0.1);
  --sbt-white-alpha-15: rgba(255, 255, 255, 0.15);
  --sbt-white-alpha-20: rgba(255, 255, 255, 0.2);
  --sbt-color-error: var(--sbt-theme-error-color, #d40909);
  --sbt-color-error-light: var(--sbt-theme-error-color-light, var(--sbt-red-10));
  --sbt-color-success: #0d830f;
  --sbt-color-warning: #e68810;

  // DMED Specific Colors
  --sbt-dmed-blue: #1750a8;
  --sbt-dmed-light-blue: #4ac0ef;
  --sbt-dmed-magenta: #9e36a8;
  --sbt-dmed-purple: #481f8b;

  // Spellbook Specific Colors
  --sbt-color-text: var(--sbt-black);
  --sbt-color-text-invert: var(--sbt-white);
  --sbt-color-background: var(--sbt-white);
  --sbt-color-body-link: var(--sbt-blue-80);
  --sbt-color-body-link-dark: var(--sbt-blue-90);
  --sbt-color-disabled-text: var(--sbt-theme-tertiary-color-30);
  --sbt-color-disabled-background: var(--sbt-theme-tertiary-color-20);
  --sbt-color-disabled-border: var(--sbt-theme-tertiary-color-30);

  // Theme specific - If no theme is set, default to blue theme
  --sbt-theme-color-10: var(--sbt-blue-10);
  --sbt-theme-color-20: var(--sbt-blue-20);
  --sbt-theme-color-30: var(--sbt-blue-30);
  --sbt-theme-color-40: var(--sbt-blue-40);
  --sbt-theme-color-50: var(--sbt-blue-50);
  --sbt-theme-color-60: var(--sbt-blue-60);
  --sbt-theme-color-70: var(--sbt-blue-70);
  --sbt-theme-color-80: var(--sbt-blue-80);
  --sbt-theme-color-90: var(--sbt-blue-90);
  --sbt-theme-color-100: var(--sbt-blue-100);

  --sbt-color-header-dark: var(--sbt-blue-70);
  --sbt-color-header-darker: var(--sbt-blue-80);
  --sbt-color-header-light: var(--sbt-blue-40);
  --sbt-color-header-lighter: var(--sbt-blue-30);

  // Theme specific secondary color, default to theme color
  --sbt-theme-secondary-color-10: var(--sbt-theme-color-10);
  --sbt-theme-secondary-color-20: var(--sbt-theme-color-20);
  --sbt-theme-secondary-color-30: var(--sbt-theme-color-30);
  --sbt-theme-secondary-color-40: var(--sbt-theme-color-40);
  --sbt-theme-secondary-color-50: var(--sbt-theme-color-50);
  --sbt-theme-secondary-color-60: var(--sbt-theme-color-60);
  --sbt-theme-secondary-color-70: var(--sbt-theme-color-70);
  --sbt-theme-secondary-color-80: var(--sbt-theme-color-80);
  --sbt-theme-secondary-color-90: var(--sbt-theme-color-90);
  --sbt-theme-secondary-color-100: var(--sbt-theme-color-100);

  // Theme specific tertiary color, default to gray
  --sbt-theme-tertiary-color-10: var(--sbt-gray-10);
  --sbt-theme-tertiary-color-20: var(--sbt-gray-20);
  --sbt-theme-tertiary-color-30: var(--sbt-gray-30);
  --sbt-theme-tertiary-color-40: var(--sbt-gray-40);
  --sbt-theme-tertiary-color-50: var(--sbt-gray-50);
  --sbt-theme-tertiary-color-60: var(--sbt-gray-60);
  --sbt-theme-tertiary-color-70: var(--sbt-gray-70);
  --sbt-theme-tertiary-color-80: var(--sbt-gray-80);
  --sbt-theme-tertiary-color-90: var(--sbt-gray-90);
  --sbt-theme-tertiary-color-100: var(--sbt-gray-100);
}
