:root {
  --sbt-green-10: #e9fae9;
  --sbt-green-20: #a9e8a9;
  --sbt-green-30: #76d677;
  --sbt-green-40: #41ba42;
  --sbt-green-50: #1c9b1e;
  --sbt-green-60: #0d830f;
  --sbt-green-70: #056c07;
  --sbt-green-80: #045a05;
  --sbt-green-90: #024703;
  --sbt-green-100: #023503;
}
