:root {
  --sbt-teal-10: #d7faf8;
  --sbt-teal-20: #83f2eb;
  --sbt-teal-30: #43ded3;
  --sbt-teal-40: #21c2b7;
  --sbt-teal-50: #0ea197;
  --sbt-teal-60: #08827a;
  --sbt-teal-70: #086962;
  --sbt-teal-80: #09524d;
  --sbt-teal-90: #0a3d3a;
  --sbt-teal-100: #0a2b29;
}
