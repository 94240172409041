:root {
  --sbt-purple-10: #f5f0fa;
  --sbt-purple-20: #ead9fa;
  --sbt-purple-30: #dabcf7;
  --sbt-purple-40: #c79bf2;
  --sbt-purple-50: #ad73e8;
  --sbt-purple-60: #9656d6;
  --sbt-purple-70: #7938b9;
  --sbt-purple-80: #5b2691;
  --sbt-purple-90: #42176c;
  --sbt-purple-100: #290c45;
}
