// Import all color tokens
@import './colors/color-default';

:root {
  --sbt-font-stack-primary: Lato, 'Helvetica Neue', Helvetica, Roboto, Arial,
    sans-serif;
  --sbt-font-stack-mono: Consolas, Menlo, 'Roboto Mono', monospace;
  --sbt-font-stack-alt: 'Avenir Next', 'Segoe UI', 'Roboto', sans-serif;

  // Size/Weight/Height based on https://prism.dmed.technology/design/structure/typography/

  --sbt-font-size-0: var(--sbt-base-unit);               // 4px 0.25rem
  --sbt-font-size-5: calc(var(--sbt-base-unit) * 1.5);   // 6px 0.375rem
  --sbt-font-size-10: calc(var(--sbt-base-unit) * 2);    // 8px 0.5rem
  --sbt-font-size-15: calc(var(--sbt-base-unit) * 2.5);  // 10px 0.625rem
  --sbt-font-size-20: calc(var(--sbt-base-unit) * 3);    // 12px 0.75rem
  --sbt-font-size-25: calc(var(--sbt-base-unit) * 3.5);  // 14px 0.875rem
  --sbt-font-size-30: calc(var(--sbt-base-unit) * 4);    // 16px 1rem
  --sbt-font-size-35: calc(var(--sbt-base-unit) * 4.5);  // 18px 1.125rem
  --sbt-font-size-40: calc(var(--sbt-base-unit) * 5);    // 20px 1.25rem
  --sbt-font-size-45: calc(var(--sbt-base-unit) * 5.5);  // 22px 1.375rem
  --sbt-font-size-50: calc(var(--sbt-base-unit) * 6);    // 24px 1.5rem
  --sbt-font-size-55: calc(var(--sbt-base-unit) * 6.5);  // 26px 1.625rem
  --sbt-font-size-60: calc(var(--sbt-base-unit) * 7);    // 28px 1.75rem
  --sbt-font-size-65: calc(var(--sbt-base-unit) * 7.5);  // 30px 1.875rem
  --sbt-font-size-70: calc(var(--sbt-base-unit) * 8);    // 32px 2rem
  --sbt-font-size-75: calc(var(--sbt-base-unit) * 8.5);  // 34px 2.125rem
  --sbt-font-size-80: calc(var(--sbt-base-unit) * 12);   // 48px 3rem
  --sbt-font-size-85: calc(var(--sbt-base-unit) * 14);   // 56px 3.5rem
  --sbt-font-size-90: calc(var(--sbt-base-unit) * 15);   // 60px 3.75rem
  --sbt-font-size-95: calc(var(--sbt-base-unit) * 16);   // 64px 4rem
  --sbt-font-size-100: calc(var(--sbt-base-unit) * 17);  // 68px 4.25rem

  --sbt-font-weight-10: 100; // thin, ultra-light
  --sbt-font-weight-20: 200; // light
  --sbt-font-weight-30: 300; // book
  --sbt-font-weight-40: 400; // regular
  --sbt-font-weight-50: 500; // medium
  --sbt-font-weight-60: 600; // demi-bold
  --sbt-font-weight-70: 700; // bold
  --sbt-font-weight-80: 800; // extra bold
  --sbt-font-weight-90: 900; // heavy bold
  --sbt-font-weight-100: 1000; // ultra bold

  --sbt-line-height-0: 0;
  --sbt-line-height-ui: 1;
  --sbt-line-height-10: 1.1;
  --sbt-line-height-20: 1.2;
  --sbt-line-height-25: 1.25;
  --sbt-line-height-30: 1.3;
  --sbt-line-height-40: 1.4;
  --sbt-line-height-50: 1.5;
  --sbt-line-height-60: 1.6;
  --sbt-line-height-70: 1.7;
  --sbt-line-height-75: 1.75;
  --sbt-line-height-80: 1.8;
  --sbt-line-height-90: 1.9;
  --sbt-line-height-100: 2;

  --sbt-border-radius-small: 3px;
  --sbt-border-radius-large: 8px;
  --sbt-border-radius-circle: 50%;

  --sbt-stroke-thin: 1px;
  --sbt-stroke-standard: 2px;
  --sbt-stroke-thick: 4px;

  --sbt-stroke-thin-black: var(--sbt-stroke-thin) solid var(--sbt-black);
  --sbt-stroke-thin-white: var(--sbt-stroke-thin) solid var(--sbt-white);
  --sbt-stroke-thin-error: var(--sbt-stroke-thin) solid var(--sbt-color-error);
  --sbt-stroke-thin-success: var(--sbt-stroke-thin) solid var(--sbt-color-success);
  --sbt-stroke-thin-warning: var(--sbt-stroke-thin) solid var(--sbt-color-warning);
  --sbt-stroke-thin-blue-60: var(--sbt-stroke-thin) solid var(--sbt-blue-60);
  --sbt-stroke-thin-gray-60: var(--sbt-stroke-thin) solid var(--sbt-gray-60);
  --sbt-stroke-thin-theme: var(--sbt-stroke-thin) solid var(--sbt-theme-color-60);

  --sbt-stroke-standard-black: var(--sbt-stroke-standard) solid var(--sbt-black);
  --sbt-stroke-standard-white: var(--sbt-stroke-standard) solid var(--sbt-white);
  --sbt-stroke-standard-error: var(--sbt-stroke-standard) solid var(--sbt-color-error);
  --sbt-stroke-standard-success: var(--sbt-stroke-standard) solid var(--sbt-color-success);
  --sbt-stroke-standard-warning: var(--sbt-stroke-standard) solid var(--sbt-color-warning);
  --sbt-stroke-standard-blue-60: var(--sbt-stroke-standard) solid var(--sbt-blue-60);
  --sbt-stroke-standard-gray-60: var(--sbt-stroke-standard) solid var(--sbt-gray-60);
  --sbt-stroke-standard-theme: var(--sbt-stroke-standard) solid var(--sbt-theme-color-60);

  --sbt-stroke-thick-black: var(--sbt-stroke-thick) solid var(--sbt-black);
  --sbt-stroke-thick-white: var(--sbt-stroke-thick) solid var(--sbt-white);
  --sbt-stroke-thick-error: var(--sbt-stroke-thick) solid var(--sbt-color-error);
  --sbt-stroke-thick-success: var(--sbt-stroke-thick) solid var(--sbt-color-success);
  --sbt-stroke-thick-warning: var(--sbt-stroke-thick) solid var(--sbt-color-warning);
  --sbt-stroke-thick-blue-60: var(--sbt-stroke-thick) solid var(--sbt-blue-60);
  --sbt-stroke-thick-gray-60: var(--sbt-stroke-thick) solid var(--sbt-gray-60);
  --sbt-stroke-thick-theme: var(--sbt-stroke-thick) solid var(--sbt-theme-color-60);

  // https://prism.dmed.technology/design/structure/elevation/
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  --sbt-elevation-0: 0;
  --sbt-elevation-20: 0 1px 3px 0 var(--sbt-black-alpha-15);
  --sbt-elevation-30: 0 2px 4px 0 var(--sbt-black-alpha-15);
  --sbt-elevation-40: 0 3px 5px 0 var(--sbt-black-alpha-15);
  --sbt-elevation-focus: 0 0 0 2px #80c3e0;
  --sbt-elevation-error: 0 0 0 0.2rem rgba(220, 53, 69, .25);

  // Look more into replacing this with a token
  --sb-button-border-radius: 2rem;

  // Inputs
  --sbt-input-height: 36px;
  --sbt-input-border-size: 1px;
  --sbt-input-border-radius: var(--sbt-border-radius-small);
  --sbt-input-border-color: var(--sbt-gray-30);
  --sbt-input-box-shadow: var(--sbt-elevation-focus);

  // Buttons
  --sbt-button-border-size: 2px;

  --sbt-base-unit: 0.25rem;

  --sbt-spacing-0: 0;
  --sbt-spacing-10: var(--sbt-base-unit);
  --sbt-spacing-20: calc(var(--sbt-base-unit) * 2); // 0.5rem (~8px)
  --sbt-spacing-30: calc(var(--sbt-base-unit) * 3);
  --sbt-spacing-40: calc(var(--sbt-base-unit) * 4); // 1rem (~16px)
  --sbt-spacing-50: calc(var(--sbt-base-unit) * 5);
  --sbt-spacing-60: calc(var(--sbt-base-unit) * 6); // 1.5rem (~24px)
  --sbt-spacing-70: calc(var(--sbt-base-unit) * 7);
  --sbt-spacing-80: calc(var(--sbt-base-unit) * 8); // 2rem (~32px)
  --sbt-spacing-90: calc(var(--sbt-base-unit) * 9);
  --sbt-spacing-100: calc(var(--sbt-base-unit) * 10); // 2.5rem (~40px)

  --sbt-navbar-height: 56px;

  --sbt-stack-base: 0;
  --sbt-stack-floating: 200;
  --sbt-stack-overlay: 400;
  --sbt-stack-modal: 600;
  --sbt-stack-max: 800;

  --sb-fixed-container-width: 1320px;
}
