// Override default variables before the import
$body-bg: var(--sbt-gray-10);
$font-family-primary: 'Lato', sans-serif;
$font-family-base: 'Lato', sans-serif;
$font-size-base: .75rem;

.btn:focus, .btn:active:focus, .btn.active:focus,
.btn.focus, .btn:active.focus, .btn.active.focus {
  outline: none !important;
  box-shadow: inset 0 -1px 0 #ddd !important;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

.form-group {
  label {
    font-weight: 700;
  }
  .form-control {
    border-color: #A1A1A1;
    border-radius: 0;
  }
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
