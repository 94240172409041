:root {
  --sbt-magenta-10: #faf0f4;
  --sbt-magenta-20: #fbd4e4;
  --sbt-magenta-30: #fab4d1;
  --sbt-magenta-40: #f78bb8;
  --sbt-magenta-50: #ef5194;
  --sbt-magenta-60: #d6246e;
  --sbt-magenta-70: #a31853;
  --sbt-magenta-80: #750e39;
  --sbt-magenta-90: #520425;
  --sbt-magenta-100: #2e0717;
}
