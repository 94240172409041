:root {
  --sbt-sky-10: #e8f4fa;
  --sbt-sky-20: #bbe5fa;
  --sbt-sky-30: #8dd4f7;
  --sbt-sky-40: #53baed;
  --sbt-sky-50: #229ad6;
  --sbt-sky-60: #0c7bb3;
  --sbt-sky-70: #066391;
  --sbt-sky-80: #064d70;
  --sbt-sky-90: #083952;
  --sbt-sky-100: #0c2938;
}
