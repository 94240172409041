/*Theme Colors*/
$azure-radiance: #09F;
$black: #000;
$brick-red: #C02C34;
$burnt-sienna: #E66F45;
$cadet-blue: #A3B5C3;
$cod-gray: #0B0A0A;
$comet: #5A647B;
$conch: #D4DDD9;
$danube: #63A7D0;
$darken-silver: #BCBCBC;
$de-york: #67C697;
$gallery: #EEE;
$gin-fizz: #FFFBE3;
$gray: #BBB;
$gray21: #363636;
$golden-grass: #D3AE1E;
$iron: #D1D3D4;
$jelly-bean: #2D8C9D;
$light-gray: #D3D3D3;
$lima: #4AAB1D;
$loblolly: #C8CFD4;
$malibu: #7FCCFF;
$mandalay: #B2761A;
$matisse: #1B6B9E;
$mercury: #E1E1E1;
$milano-red: #AE1700;
$mine-shaft: #3D3D3D;
$nebula: #D4DDDA;
$nepal: #8DABC2;
$nutmeg: #7E3B2A;
$orinoco: #EDFCD5;
$silver-chalice: #A7A7A7;
$silver-chalice-dark: #A1A1A1;
$silver: #CCC;
$steel-blue: #6194BC;
$timber-wolf: #D3D1C8;
$totem-pole: #A01303;
$tuft-bush: #FFDBC7;
$we-peep: #FAE6E7;
$waterloo: #7D8494;
$wedgewood: #4D80A0;
$wedgewood-dark: #4B80A0;
$white: #FFF;
$ziggurat: #B8D9DD;
$comet-dark: #58607A;
$white-smoke: #F1F1F1;

/* Background */
$bg-generic-icon: $milano-red;
$bg-module-header: $comet;
$bg-primary: $white;
$bg-secondary: $gallery;
$bg-tertiary: $mine-shaft;
$bg-dropdown-focus: $tuft-bush;
$border-dropdown: $silver-chalice-dark;
$border-primary: $silver;

/* Fonts */
$font-error: $brick-red;
$font-inactive: $iron;
$font-label: $silver-chalice;
$font-link-secondary: $nepal;
$font-link: $matisse;
$font-primary: $mine-shaft;
$font-secondary: $white;
$font-warning: $totem-pole;

/* Button Colors */
$btn-bg-inactive: $iron;
$btn-bg-primary-hover: $nutmeg;
$btn-bg-primary: $burnt-sienna;
$btn-bg-secondary-hover: $wedgewood;
$btn-bg-secondary: $nepal;
$btn-bg-tertiary: $gallery;

/* Errors */
$inline-error-bg-color: $we-peep;
$inline-error-font-color: $brick-red;
$warning-icon-color: $golden-grass;
$warning-bg-color: $gin-fizz;
$warning-font-color: $mandalay;