.container {
    display: grid;
    grid-template: 1fr / repeat(3, 1fr); // 1 row of 1 fraction, 3 columns of 1 fraction
    gap: var(--sbt-spacing-40);
    height: 78dvh;
    overflow: hidden;

    &>[data-position="left"],
    &>[data-position="right"] {
        background-color: var(--sbt-white);
        border-radius: var(--sbt-border-radius-large);
        border: solid 1px var(--sbt-theme-tertiary-color-20);
        height: inherit;
        overflow: hidden;
    }

    &>[data-position="left"] {
        grid-row: 1;
        grid-column: 1;
    }

    &>[data-position="right"] {
        grid-row: 1;
        grid-column: 1 / span 3;
    }
}