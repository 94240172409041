:root {
  --sbt-yellow-10: #fcfcee;
  --sbt-yellow-20: #fcf6cc;
  --sbt-yellow-30: #faec9d;
  --sbt-yellow-40: #f7e171;
  --sbt-yellow-50: #f2d24e;
  --sbt-yellow-60: #ebc438;
  --sbt-yellow-70: #d1a917;
  --sbt-yellow-80: #ad8705;
  --sbt-yellow-90: #8a6901;
  --sbt-yellow-100: #6b5203;
}
