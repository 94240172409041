:root {
  --sbt-indigo-10: #f2f2fc;
  --sbt-indigo-20: #dcdcfc;
  --sbt-indigo-30: #c2c2fc;
  --sbt-indigo-40: #a7a7fa;
  --sbt-indigo-50: #8585f2;
  --sbt-indigo-60: #6767e6;
  --sbt-indigo-70: #4d4dd1;
  --sbt-indigo-80: #3333a6;
  --sbt-indigo-90: #1e1e7b;
  --sbt-indigo-100: #111149;
}
