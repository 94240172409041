body {
  margin: 0;
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Avenir Next", "Helvetica Neue", "Arial",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #213168 !important;
}
#main-content {
  margin-top: 10px !important;
  background-color: #EEEEEE !important;
  background-image: url("images/white-hex-4k-bg-1024x576-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: lighten;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.TAPCard {
  background-color: black !important;
}
.TAPWhiteBG {
  background-color: black !important;
}
header {
    color: black;
}
header > div > button.MuiIconButton-colorInherit:first-of-type {
  background-color: #4265C3 !important;
  color: white !important;
  height: 55px;
  width: 55px;
}
header > div > button.MuiIconButton-colorInherit {
  background-color: transparent !important;
  color: white !important;
}
header > div > div, header > div > div > button {
  background-color: transparent !important;
  color: white !important;
  height: 55px;
}
section > div.ra-input {
    width: 100% !important;
}

.MuiAppBar-root > .MuiToolbar-root > button {
  height: 55px
}

.MuiAppBar-root > .MuiToolbar-root > h6 > span {
  font-size: 20px !important;
  font-weight: 500;
}

.MuiDrawer-paperAnchorLeft > div > div > a, .MuiDrawer-paperAnchorLeft > div > div > a > div {
  color: white;
}

.MuiDrawer-paperAnchorLeft > div > div > a > div > svg.active {
  color: #4265C3;
}

td.column-score {
  padding: 0 !important;
  display: inline-block;
  height: 100%;
}
td.column-verifiedDatetime {
  padding: 0 !important;
  height: 100%;
}
p, span {
  font-size: 16px;
}
p {
  font-size: 20px;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}

.bc {
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.bc svg {
  margin-right: 5px;
  transition: opacity 0.2s linear;
}

.bc:last-child > svg {
  opacity: 0;
}

.bc span {
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.bc,
.content {
  display: flex;
}

.app {
  flex-direction: column;
}
.Toastify__toast--default {
    background: #fff;
    color: black !important;
}

test {
    color: rgba(0, 0, 0, 0.95) !important;
}

section > div.ra-input {
  width: 20%;
  float: left;
}

.Toastify__toast-body {
    height: 50px;
    width: 100px;
}
.MuiDialog-paperWidthLg {
    max-width: 100% !important;
    width: 100% !important;
    margin: 2px !important;
    height: 98% !important;
    max-height: 98% !important;
}
#sharedSectionId-helper-text {
  display: none !important;
}
.MuiDialogContent-root {
  padding: 0 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.ql-toolbar.ql-snow {
    position: fixed;
    width: 32%;
    background-color: white;
    z-index: 1;
    height: 76px;
    overflow-y: auto;
    border: none !important;
}
.ql-container {
    padding-top: 60px;
    position: relative;
}
.ql-container.ql-snow {
  border: none !important;
}
.ql-editor {
  padding: 16px 12px;
  margin-top: 7px;
  min-height: 55vh;
}
#cm-memo-text > .MuiTextField-root  {
  margin-bottom: 0;
}
.ra-rich-text-input  {
  margin-bottom: 0 !important;
}
.ra-rich-text-input >  .MuiFormHelperText-root {
  display: none;
}
.ClearanceReport-Header {
  padding: 1px !important;
  align-items: flex-start !important;
}
.selected-feature-node {
  border: 1px solid #83CEFF;
}
.rst__row {
 cursor: pointer;
}
.selected-feature-node > .rst__rowContents {
  background-color: #83CEFF;
}
.rst__rowSubtitle {
    margin-top: 10px;
    display: block;
}
pre {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Arial,sans-serif;
}
.SharedSectionsForm > .MuiCardContent-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.DraftEditor-editorContainer {
  max-height: 50vh;
  overflow-y: auto;
}
#product-comment-dialog > div > div > div > div > .DraftEditor-editorContainer {
  max-height: 40vh;
}
.jodit-toolbar-collection {
  position: absolute !important;
  top: 0;
  overflow-x: auto !important;
}
.jodit-workplace {
  position: absolute !important;
  width: 100%;
  margin-top: 20px !important;
  max-height: 50vh !important;
  overflow-y: auto !important;
  overflow-x: auto !important;
}
#product-comment-dialog > div > div > .jodit-workplace {
  max-height: 35vh !important;
  overflow-y: auto !important;
}
.jodit-container:not(.jodit_inline) {
  border-bottom: 0 !important;
  border-radius: 0 !important;
  overflow-y: auto !important;
  overflow-x: auto !important;
}
#WindowsArray > div > ul > li {
  float: left;
  border: 1px solid lightgrey;
  margin: 10px;
  border-radius: 9px;
  padding: 9px;
  max-width: 500px;
  height: 500px;
  overflow-y: auto;
  margin-top: 25px;
}
#WindowsArray > div > ul > li:nth-last-child(1) {
  height: 45px;
  border: none;
}
#WindowsArray .MuiFormControl-root {
  margin-bottom: 0;
  margin-top: 0;
}
.MuiDrawer-paper {
  width: 90%
}
.rateCardHeaderRowColumn {
  min-width: 300px;
  width: 275px;
  max-width: 300px;
  margin: 10px;
  position: relative;
}
.rateCardHeaderRowRemoveColumn {
  margin-top: 11px !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  color: red !important;
  position: absolute !important;
  left: 280px !important;
  top: -15px !important;
  z-index: 2;
}
.rateCardRowRemoveTier {
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  color: red !important;
  position: relative !important;
  left: -15px !important;
  top: 24px !important;
  z-index: 2;
}
.rateCardRowRemoveTierPlaceholder {
  width: 100%;
  height: 20px;
}
.rateCardRowRemoveTierPricePlaceholder {
  width: 100%;
  height: 20px;
}
.rateCardPercentageInput {
  min-height: 105px;
  margin-top: 53px !important;
}
.breakRow {
  clear: both;
}
.displayContentsSpan {
  display: contents;
}
.tierColumnHeaders {
  margin-bottom: 10px;
  background-color: #3333;
  overflow-x: scroll;
}
.tierFormatControl {
  min-width: 400px;
  height: 100px;
}
.tierRows {
  margin-bottom: 10px;
  background-color: #3333;
  overflow-x: scroll;
}
.rateCardTierRow {
  min-width: 275px;
  width: 275px;
  max-width: 275px;
  margin: 10px;
  position: relative;
  float: left;
}
.react-confirm-alert-overlay {
  z-index: 999999 !important;
}
.column-view, .column-edit {
  max-width: 12px;
  padding-left: 5px !important;
}

.column-edit > a > span > span  {
  display: none;
}
#RateCardList  > div > div > table > thead > tr > th:nth-child(2), #RateCardList  > div > div > table > thead > tr > th:nth-child(3) {
  max-width: 10px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

#WindowSequenceList  > div > div > table > thead > tr > th:nth-child(2), #WindowSequenceList  > div > div > table > thead > tr > th:nth-child(3) {
  max-width: 10px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

#LicensingList  > div > div > table > thead > tr > th:nth-child(2), #LicensingList  > div > div > table > thead > tr > th:nth-child(3), #LicensingList  > div > div > table > thead > tr > th:nth-child(4) {
  max-width: 40px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

#LicensingList  > div > div > table > thead > tr > th:nth-child(11), #LicensingList  > div > div > table > thead > tr > th:nth-child(12) {
  text-align: center;
}

#LicensingList  > div > div > table > thead > tr > th:nth-child(10), #LicensingList  > div > div > table > thead > tr > th:nth-child(11) {
  min-width: 150px
}

#LicensingList  > div > div > table > thead > tr > th:nth-child(19), #LicensingList  > div > div > table > thead > tr > th:nth-child(21), #LicensingList  > div > div > table > thead > tr > th:nth-child(23) {
  min-width: 230px
}

#LicensingList  > div > div > table > thead > tr > th:nth-child(16) {
  min-width: 250px
}

#LicensingList  > div > div > table > thead > tr > th:nth-child(17) {
  min-width: 22px
}

#LicensingList>div>div>table>thead>tr>th:nth-child(18), #LicensingList>div>div>table>thead>tr>th:nth-child(19), #LicensingList>div>div>table>thead>tr>th:nth-child(21), #LicensingList>div>div>table>thead>tr>th:nth-child(23) {
  min-width: 255px;
}

#LicensingList>div>div>table>thead>tr>th:nth-child(17), #LicensingList>div>div>table>thead>tr>th:nth-child(18), #LicensingList>div>div>table>thead>tr>th:nth-child(20), #LicensingList>div>div>table>thead>tr>th:nth-child(22) {
  min-width: 150px;
}

.column-rateCardName, .column-windowSequenceName {
  min-width: 200px
}
.column-rateCardDescription {
  min-width: 200px
}
.column-windowSequence\.windowSequenceName {
  min-width: 200px
}
.column-currency {
  min-width: 125px
}
.column-revShareType {
  min-width: 75px
}
.column-revShareType, .column-createdBy\.fullName {
  min-width: 75px
}
.column-createdBy\.fullName {
  min-width: 100px
}
.column-modifiedBy\.fullName {
  min-width: 100px
}
.column-rightTitle {
  min-width: 400px
}
.MuiFormLabel-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}
.tierRowTierNumberInput > .MuiFormHelperText-root {
  min-height: 20px;
}
.tierNameInput > .MuiFormHelperText-root {
  min-height: 35px;
}
.mainLogin {
  height: 100%;
  min-height: 100vh;
  text-align: center;
  width: 100%;
  margin: auto;
  background-color: #213168 !important;
}
.mainCard {
  padding-top: 5%;
  box-shadow: none !important;
  background-color: #213168 !important;
  color: white !important;
}
.myIdButton {
  border-radius: 10px
}
.tss-1qjwhn0-MUIDataTableBody-emptyTitle {
    text-align: left !important;
}
.MuiToolbar-regular {
    max-width: 80vw !important;
}
.tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft{
  background: white;
}
.tss-xfsg3u-MUIDataTableSelectCell-expandDisabled > svg {
  opacity: 0 !important;
}

#PmxCuratedDatesAndPricingList  > div > div > table > thead > tr > th {
  min-width: 100px;
}
#PmxCuratedDatesAndPricingList  > div > div > table > thead > tr > th:nth-child(3) {
  min-width: 300px;
}

#main-content > div > div > .MuiPaper-rounded {
  max-width: 89vw;
}
#main-content > div > .MuiPaper-rounded {
  max-width: 89vw;
}
#LicensingForm {
  margin-bottom: 50px;
}
#LicensingForm > * li {
  border-bottom: none !important;
}
#LicensingForm > * li:first-child {
  width: 98%;
  margin-top: 12px;
}

#LicensingForm > * li:first-child > span > .button-remove {
  display: none;
}
#EditCatalog > * li:first-child > span > .button-remove {
  display: block;
}
#AddCatalog > * li:first-child > span > .button-remove {
  display: block;
}
#DealsOnJob > * .button-remove {
  display: block !important;
}
.MuiTablePagination-root:last-child {
    padding: 0;
    padding-right: 10vw !important;
}

.tss-1cdcmys-MUIDataTable-responsiveBase {
  max-height: 65vh;
}

.MuiTablePagination-toolbar {
    width: 10vw !important;
}
.tss-djbknv-MUIDataTablePagination-navContainer {
    justify-content: flex-start !important
}

.job-success-true {
  color: green
}

.job-success-false {
  color: red
}

.catalogsLicensingContainer {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.catalogLicensing {
  height: 100%;
  display: flex;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: pre;
  background-color:#e0e0e0;
  border-radius:16px;
  padding: 0 8px;
}

.showAllCatalogsLicensing {
  color:#02C454;
  text-align:right;
  cursor:pointer;
  margin:0;
}

.showLimitCatalogsLicensing {
  color:#F66940;
  text-align:right;
  cursor:pointer;
  margin:0;
}

.territoriesLicensingContainer {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.territoryLicensing {
  height: 100%;
  display: flex;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: pre;
  background-color:#e0e0e0;
  border-radius:16px;
  padding: 0 8px;
}

.showAllTerritoriesLicensing {
  color:#02C454;
  text-align:right;
  cursor:pointer;
  margin:0;
}

.showLimitTerritoriesLicensing {
  color:#F66940;
  text-align:right;
  cursor:pointer;
  margin:0;
}