:root {
  --sbt-rust-10: #fcf1ed;
  --sbt-rust-20: #fcd8cf;
  --sbt-rust-30: #ffb5a1;
  --sbt-rust-40: #fb9073;
  --sbt-rust-50: #ed643e;
  --sbt-rust-60: #cf4119;
  --sbt-rust-70: #ab2c09;
  --sbt-rust-80: #821e03;
  --sbt-rust-90: #5c1603;
  --sbt-rust-100: #3b1106;
}
