:root {
  --sbt-cyan-10: #dbf8ff;
  --sbt-cyan-20: #aae4f2;
  --sbt-cyan-30: #7fd0e6;
  --sbt-cyan-40: #4bbcd9;
  --sbt-cyan-50: #13a1c6;
  --sbt-cyan-60: #0381a1;
  --sbt-cyan-70: #06677f;
  --sbt-cyan-80: #0b4e5e;
  --sbt-cyan-90: #0b3641;
  --sbt-cyan-100: #0c282f;
}
