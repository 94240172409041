:root {
  --sbt-lime-10: #f1fee2;
  --sbt-lime-20: #d0f7a5;
  --sbt-lime-30: #a7e75d;
  --sbt-lime-40: #84d626;
  --sbt-lime-50: #71ba1c;
  --sbt-lime-60: #599c0d;
  --sbt-lime-70: #4b820c;
  --sbt-lime-80: #386208;
  --sbt-lime-90: #2f4c10;
  --sbt-lime-100: #23390b;
}
