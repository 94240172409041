:root {
  --sbt-gray-10: #edeef0;
  --sbt-gray-20: #dcdddf;
  --sbt-gray-30: #c7c7c9;
  --sbt-gray-40: #a1a2a3;
  --sbt-gray-50: #8d8e90;
  --sbt-gray-60: #6c6e6f;
  --sbt-gray-70: #48494a;
  --sbt-gray-80: #2b2c2d;
  --sbt-gray-90: #1d1e1f;
  --sbt-gray-100: #151617;
}
