:root {
  --sbt-red-10: #fae8e8;
  --sbt-red-20: #fcc;
  --sbt-red-30: #faacac;
  --sbt-red-40: #ff7f7f;
  --sbt-red-50: #f95252;
  --sbt-red-60: #d40909;
  --sbt-red-70: #a10202;
  --sbt-red-80: #730505;
  --sbt-red-90: #520707;
  --sbt-red-100: #2d0909;
}
