:root {
  --sbt-violet-10: #faebfa;
  --sbt-violet-20: #fad4f9;
  --sbt-violet-30: #f0b4ef;
  --sbt-violet-40: #ed8cec;
  --sbt-violet-50: #e060dd;
  --sbt-violet-60: #cb35c8;
  --sbt-violet-70: #a318a1;
  --sbt-violet-80: #750e74;
  --sbt-violet-90: #520450;
  --sbt-violet-100: #2e072d;
}
