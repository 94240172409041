@import "src/screens/licensing/Spellbook-poc/styles/mixins/badges.scss";

.dataGrid {
    :global {
        .ag-root-wrapper {
            --ag-alpine-active-color: var(--sbt-theme-color-70);
            --ag-background-color: transparent;
            --ag-checkbox-checked-color: var(--sbt-theme-color-70);
            --ag-checkbox-unchecked-color: var(--sbt-gray-30);
            --ag-header-column-resize-handle-height: var(--sbt-spacing-40);
            --ag-row-hover-color: var(--sbt-gray-10);
            --sb-root-wrapper-border-radius: 0;

            .ag-header {
                border-bottom: solid 1px var(--sbt-gray-20);

                &-cell {
                    color: var(--sbt-gray-50);
                    padding-top: 0;
                    padding-bottom: 0;

                    &:first-child,
                    &:last-child {
                        border: none;
                    }

                    & [class*="sb-cp-standard-table_header_menu-icon"] {
                        display: none;
                    }

                    & .ag-checkbox {
                        margin: 0 var(--sbt-spacing-100) 0 var(--sbt-spacing-10);
                    }
                }
            }

            .ag-body {
                .ag-row {
                    border: none;
                    border-bottom: solid 1px var(--sbt-gray-20);
                    border-radius: 0;
                    margin: 0;
                    max-height: none;

                    &.ag-row-selected {
                        background-color: var(--sbt-theme-color-10);
                    }

                    &:hover {
                        background-color: var(--sbt-theme-color-10);
                    }

                    & .ag-cell {
                        & .sb-typography {
                            margin: 0;

                            &.sb-typography_clickable {
                                text-decoration: underline;
                            }
                        }

                        &-value .sb-standard-table_cell-master-detail div:nth-of-type(2) {
                            overflow: hidden;
                        }
                    }
                }
            }

            .ag-row-odd {
                width: stretch !important;
            }
        }
    }

    & button {
        border: none;
        background-color: transparent;
        padding: 0;
        width: 100%;
    }

    .badge-cell {
        display: inline-flex;
        gap: var(--sbt-spacing-10);

        :global {
            @include use-badge;
            @include use-base-badge;
            @include use-success-badge;
            @include use-warning-badge;
            @include use-error-badge;
        }
    }

    .details-row {
        background-color: var(--sbt-white);
        display: grid;
        grid-auto-rows: auto;
        grid-template-columns: repeat(2, 1fr);
        height: fit-content;

        .row-item {
            //align-items: center;
            gap: 18px;
            padding: 14px;
            border-bottom: solid 1px var(--sbt-gray-20);

            &:nth-child(odd) {
                padding-left: 68px;
            }

            & [class*="sb-typography"] {
                margin: 0;

                &:last-child {
                    color: var(--sbt-gray-70);
                }
            }
        }
    }
}
