Table.Grid {
  font-family: 'Lato', sans-serif;
  font-size: $font-size-12;
  margin: 0;
  color: $mine-shaft;
  max-width: 100%;
  th {
    background-color: $loblolly;
    font-weight: $font-bold;
    height: 30px;
    position: sticky;
    top: 0;
    border: 0;
    padding: 0;
    white-space: nowrap;
    > div {
      position: relative;
      padding: 7px 16px 7px 7px;
      display: inline-block;
      span {
        position: absolute;
        display: inline-block;
        right: 7px;
        top: 50%;
        transform: translate(50%, -50%);
      }
    }

  }
  td {
    border-color: $darken-silver;
    border: 0;
    font-weight: $font-regular;
    padding: 4px;
    vertical-align: middle;
    white-space: break-spaces;
    word-break: break-word;
    &:hover {
      cursor: pointer;
    }
  }
  tr:nth-child(odd) {
    background-color: $gallery;
  }
  tr:nth-child(even) {
    background-color: $white;
  }
  tr:hover {
    background-color: $malibu
  }
}
