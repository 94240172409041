/* PLACEHOLDER - Shared styles */
%button {
  padding: 0 20px;
  border-radius: 3px;
  border: solid 1px;
  height: 37px;
  outline: none;
  color: $font-secondary;
  font-size: $font-label-size;
  margin: 7px;
  min-width: 80px;
  cursor: pointer;

  &:disabled, &.btn_inactive, &.btn_inactive:hover {
    background-color: $btn-bg-inactive;
    border-color: $btn-bg-inactive;
    cursor: default;
  }
}

@mixin btn-bg-color($main, $hover) {
  @extend %button;
  background-color: $main;
  border-color: $main;
  &:hover {
    background-color: $hover;
    border-color: $hover;
  }
}

/* Button Types */
.btn_primary {
  @include btn-bg-color($btn-bg-primary, $btn-bg-primary-hover);
  font-weight: $font-bold;
}

.btn_secondary {
  @include btn-bg-color($btn-bg-secondary, $btn-bg-secondary-hover);
  font-weight: $font-bold;
}

.btn_tertiary {
  @extend %button;
  font-weight: $font-bold;
  color: $font-link;
  background-color: $btn-bg-tertiary;
  border-color: $btn-bg-tertiary;
  &:hover {
    border-color: $font-link;
  }
  &.btn_inactive, &.btn_inactive:hover {
    background-color: $btn-bg-tertiary;
    color: $font-inactive;
  }
}

.icon-btn {
  color: #3d3d3d;
  font-size: 12px;

  .MuiSvgIcon-root {
    font-size: 1.3rem;
  }

}

.icon-btn:hover {
  cursor: pointer;
  color: #000;
}
 