@mixin use-badge {

    & [class*="sb-badge"],
    & .sb-badge {
        background-color: var(--sbt-white);
        border: solid 1px var(--sbt-gray-20);
        color: var(--sbt-theme-color-80);
    }
}

@mixin use-active-badge {

    & [class*="sb-badge_active"],
    & .sb-badge_active {
        background-color: var(--sbt-indigo-10);
        border: solid 1px var(--sbt-indigo-20);
        color: var(--sbt-indigo-80);
    }
}

@mixin use-primary-badge {

    & [class*="sb-badge_primary"],
    & .sb-badge_primary {
        background-color: var(--sbt-primary-10);
        border: solid 1px var(--sbt-primary-20);
        color: var(--sbt-primary-70);
    }
}

@mixin use-secondary-badge {

    & [class*="sb-badge_secondary"],
    & .sb-badge_secondary {
        background-color: var(--sbt-white);
        border: solid 1px var(--sbt-gray-20);
        color: var(--sbt-theme-color-80);
    }
}

@mixin use-error-badge {

    & [class*="sb-badge_error"],
    & .sb-badge_error {
        background-color: var(--sbt-red-10);
        border: solid 1px var(--sbt-red-20);
        color: var(--sbt-red-70);
    }
}

@mixin use-warning-badge {

    & [class*="sb-badge_warning"],
    & .sb-badge_warning {
        background-color: var(--sbt-orange-10);
        border: solid 1px var(--sbt-orange-20);
        color: var(--sbt-orange-80);
    }
}

@mixin use-success-badge {

    & [class*="sb-badge_success"],
    & .sb-badge_success {
        background-color: var(--sbt-green-10);
        border: solid 1px var(--sbt-green-20);
        color: var(--sbt-green-80);
    }
}

@mixin use-base-badge {

    & [class*="sb-badge_base"],
    & .sb-badge_base {
        background-color: var(--sbt-theme-tertiary-color-10);
        border: solid 1px var(--sbt-theme-tertiary-color-20);
        color: var(--sbt-theme-tertiary-color-80);
    }
}
