:root {
  --sbt-blue-10: #ebf2ff;
  --sbt-blue-20: #cfe0ff;
  --sbt-blue-30: #afcbff;
  --sbt-blue-40: #85b0ff;
  --sbt-blue-50: #5990f6;
  --sbt-blue-60: #3772df;
  --sbt-blue-70: #2358ba;
  --sbt-blue-80: #143f8f;
  --sbt-blue-90: #0e2d68;
  --sbt-blue-100: #0b1a37;
}
