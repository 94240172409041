:root {
  --sbt-orange-10: #fff7ed;
  --sbt-orange-20: #ffddb0;
  --sbt-orange-30: #ffc880;
  --sbt-orange-40: #ffb759;
  --sbt-orange-50: #faa02a;
  --sbt-orange-60: #e68810;
  --sbt-orange-70: #cf7704;
  --sbt-orange-80: #b06400;
  --sbt-orange-90: #8f5203;
  --sbt-orange-100: #7a4705;
}
