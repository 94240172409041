@import './App.scss';
@import './variables/module';
@import './components/module';
// Import all spellbook base styles
@import './spellbook-tokens';

:root {
    --sbt-font-stack-primary: "InspireTWDC", sans-serif;
}

* {
    font-family: var(--sbt-font-stack-primary);
}
