:root {
  --sbt-slate-10: #ebf2f7;
  --sbt-slate-20: #c7e0f2;
  --sbt-slate-30: #a2cbe8;
  --sbt-slate-40: #80b4d9;
  --sbt-slate-50: #5f97bf;
  --sbt-slate-60: #477ba1;
  --sbt-slate-70: #3a6687;
  --sbt-slate-80: #2d4d63;
  --sbt-slate-90: #243745;
  --sbt-slate-100: #1e2930;
}
