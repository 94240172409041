.nav-tabs {

  & > div.nav-link {
    color: #fff;
    background-color: #999;
    cursor: pointer;
    margin-right: 10px;
    position: relative;
    border: 1px solid #ccc;
    padding: 0px;
    margin-right: 10px;
    margin-bottom: -1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -ms-flex-negative: 1;
    flex-shrink: 1;

    &.active {
      color: #3d3d3d;
      background-color: #fff;
      cursor: pointer;
      border-bottom-color: #fff;

      a {
        color: #3d3d3d;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &.inactive {
      color: #fff;
      cursor: pointer;

      a {
        color: white;

        &:hover {
          text-decoration: none;
          background-color: #4b80a0;
        }
      }
    }

    a {
      padding: 0.5rem 1rem;
      display: block;
      text-decoration: none;
    }

    // a {
    //   color: #3d3d3d;
    //   display: block;
    //   padding: 0.5rem 1rem !important;
    //   &:hover {
    //     text-decoration: none;
    //     color: white;
    //   }
    // }
    // &:hover {
    //   text-decoration: none;
    //   background-color: #4b80a0;
    // }
  }

  .tab-content {
    border: 1px;
    border-top: 0;
    border-color: #dee2e6 !important;
    border-style: solid;
  }
}